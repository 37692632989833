import React from "react";
import Header from "../components/header";
import Footer1 from "../components/footer-1";
import { isLoggedIn } from "../services/auth";
import { useEffect } from "react";
import { navigate } from "gatsby";
import Helmet from "react-helmet";
import $ from "jquery";
import { withPrefix } from "gatsby";

export default function Layout() {
  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/signin");
    }

  }, []);

  const createIt = (e) => {
    e.preventDefault();
  };
  return (
    <>
      <div className="container-main" id="page">
        <Helmet>
          <script
            src={withPrefix("assets/js/popper.min.js")}
            type="text/javascript"
          />
          <script
            src={withPrefix("assets/js/bootstrap.min.js")}
            type="text/javascript"
          />
          <script
            src={withPrefix("assets/js/custom.js")}
            type="text/javascript"
          />
          <script
            src={withPrefix("assets/js/python.js")}
            type="text/javascript"
          />
          <script
            src={withPrefix("assets/js/mockup.js")}
            type="text/javascript"
          />
        </Helmet>
        <Header></Header>
        <main className="content-main">
          <div className="feature-list">
            <div className="container">
              <div className="heading-top">
                <h2>Design Mockup</h2>
                <div className="see-more">
                  <a href="#">Add product</a>
                </div>
              </div>
              {/* Page */}
              <div className="design-mockup">
                <ul className="mockup-tabs">
                  <li className="mockup-tab active" data-id="front">
                    Front &amp; Pocket
                  </li>
                  <li className="mockup-tab" data-id="top">
                    Top panel
                  </li>
                  <li className="mockup-tab" data-id="bottom">
                    Bottom panel
                  </li>
                  <li className="mockup-tab" data-id="inside">
                    Inside pocket
                  </li>
                </ul>
                <div className="designer-toolbar" />
                <div className="main-mockup-area">
                  <div className="generator-mockup">
                    <div className="upload-design">
                      <img
                        className="btn-upload"
                        src="assets/mockup/tap-your-design-here.svg"
                        alt=""
                      />
                      <input type="file" className="upload-file" />
                    </div>
                    <div className="mockup-design">
                    </div>
                    <div className="backpack-mockup inside">
                      <img src="assets/mockup/backpack/inside.png" alt="" />
                      <span className="safe-print-area-bottom">
                        Safe Print Area
                      </span>
                      <span className="placement-on-product">
                        Placement on product
                      </span>
                    </div>
                  </div>
                </div>
                <div className="designer-toolbar">
                  <span className="scale-button minus">-</span>
                  <input
                    type="range"
                    className="scale-slider"
                    min="0.1"
                    max={2}
                    defaultValue={1}
                    step="0.01"
                  />
                  <span className="scale-button plus">+</span>
                </div>
              </div>
              {/* ./Page */}
              <div className="btn-out">
                <button onClick={createIt} className="btn btn-blue">
                  Setup Store
                </button>
              </div>
            </div>
          </div>
        </main>
        <Footer1></Footer1>
      </div>
    </>
  );
}
